import { CampaignMessageKind } from '@interfaces';
import styled, { css, CSSObject } from 'styled-components';

interface TextProps {
  color: string;
  kind?: CampaignMessageKind;
  additionalStyles?: CSSObject;
  fontFamily?: string;
  fontWeight?: string;
}

const Text = styled.p<TextProps>`
  color: ${({ color }) => color};
  font-weight: 400;
  margin: 0px;

  ${({ kind }) =>
    (kind === 'button' || kind === 'link') &&
    css`
      font-weight: 700;
      text-align: center;
    `}

  /* button */

  font-family: ${({ fontFamily }) => fontFamily};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

Text.defaultProps = {
  fontFamily: 'Roboto, san-serif',
  fontWeight: 'bold',
};

export default Text;
