import styled from 'styled-components';

export const PlaceholdersWrapper = styled.div`
  width: 100%;
`

export const TitlePlaceholder = styled.div`
  width: 464px;
  height: 16px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 24px;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

export const DescriptionPlaceholder = styled.div`
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);
  margin: 20px 0px;
`

export const CalendarPlaceholder = styled.div`
  height: 730px;
  width: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);
`

export const CTAPlaceholder = styled.div`
  width: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  height: 48px;
`