import styled, { css, CSSObject } from 'styled-components';

interface Props {
  additionalStyles: CSSObject;
}

const Banner = styled.div<Props>`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

export default Banner;
