import styled, { CSSObject, css } from 'styled-components';

interface Props {
  additionalStyles?: CSSObject;
  removeBottomRadius?: boolean;
  mobileStyles?: CSSObject;
}

const DescriptionSection = styled.section<Props>`
  @media screen and (max-width: 768px) {
    ${({ mobileStyles }) => mobileStyles && css(mobileStyles)}
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}

  ${({ removeBottomRadius }) =>
    removeBottomRadius ? 'border-bottom-right-radius: 0px' : ''};
  ${({ removeBottomRadius }) =>
    removeBottomRadius ? 'border-bottom-left-radius: 0px' : ''}
`;

DescriptionSection.defaultProps = {
  removeBottomRadius: true,
  /* TODO: move Roche default to db */
  additionalStyles: {
    padding: '0 24px 16px',
    background: 'none',
  },
  mobileStyles: undefined,
};

export default DescriptionSection;
