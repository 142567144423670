import styled from 'styled-components';
import LogoSvg from '@components/Logo/assets/Logo';

const Logo = styled(LogoSvg)`
  width: 100%;
  height: 2rem;
  width: auto;
  user-select: none;
  margin-left: 5px;
`;

export default Logo;
