/* eslint-disable jsx-a11y/media-has-caption */
import { FunctionComponent, useEffect, useState } from 'react';
import MainContent from '@components/Themes/Custom/views/MainContent';
import TitleSection from '@components/Themes/Custom/views/TitleSection';
import Title from '@components/Themes/Custom/views/Title';
import VideoSection from '@components/Themes/Custom/views/VideoSection';
import DescriptionSection from '@components/Themes/Custom/views/DescriptionSection';
import Description from '@components/Themes/Custom/views/Description';
import ActionSection from '@components/Themes/Custom/views/ActionSection';
import QuestionSection from '@components/Themes/Custom/views/QuestionSection';
import QuestionTitle from '@components/Themes/Custom/views/QuestionTitle';
import QuestionContent from '@components/Themes/Custom/views/QuestionContent';
import CallToAction from '@components/Themes/Custom/CallToAction';

import RenderIf from '@components/RenderIf';
import { Campaign, CampaignMessage, EnvConfigShare } from '@interfaces';
import { useEditPageUpdates, useIsEditPage, useSegmentTracker } from '@hooks';
import { ANALYTICS_TITLE, THEMES } from '@constants/constants';
import { CSSObject } from 'styled-components';
import Banner from '@components/Themes/Custom/views/Banner';

import { useVisitorId } from '@hooks/lib/useVisitorId';
import VideoPlayer from '@sendspark/player';
import {
  getAhoyAnalyticsTrackerConfig,
  useAhoyAnalyticsTracker,
  useAnalytics,
} from '@sendspark/analytics';
import { usePlayerTrackingEvents } from '@hooks/lib/usePlayerTrackingEvents';
import { Campaign as SharedCampaign } from '@sendspark/types';
import { reemplaceVariables } from '@utils';
import { useRouter } from 'next/router';
import HeaderSection from './views/HeaderSection';
import LayoutContent from './views/LayoutContent';
import { environmentsConfig } from '../../../config';
import HeadersCustomTheme from '@components/Headers/CustomTheme';
import Layout from '@components/CompoundLayout';
import { StyledWrapper } from '@components/CompoundLayout/styles';
// import BodyBackground from './Layout/styles/BodyBackground';
import Calendar from '@components/Calendars';
import Footer from '@components/Layout/styles/Footer';
import VideoMessageSign from '@components/Layout/styles/VideoMessageSign';
import LogoLink from '@components/Layout/styles/LogoLink';
import Logo from '@components/Layout/styles/Logo';
import OptionalExtraMargin from './views/OptionalExtraMargin';

interface Props {
  campaign: Campaign;
  env: EnvConfigShare;
}

const CustomTheme: FunctionComponent<Props> = ({
  campaign: initialCampaign,
  env,
}) => {
  // override styles color
  const style = {
    ...initialCampaign.style,
    ...initialCampaign?.theme?.properties?.style,
    // PROPS used
    // heading1: "#c501f1",
    // heading2: "#674c6d",
    // background: "#fff",
    // responseButtonBackground: "#0066CC",
    // responseButtonText: "#fff",
    // mainContentBackground: #FFF,
  };

  const [campaign, setCampaign] = useState<Campaign>({
    ...initialCampaign,
    style,
    sendsparkBrandingEnabled: false,
  });

  const contextGroupId = campaign.creator?.workspaceId
  const segmentTracker = useSegmentTracker(env.apiV2Url, contextGroupId, env.cryptoKey);

  const isEditPage = useIsEditPage();
  const visitorId = useVisitorId();

  const playerEvents = usePlayerTrackingEvents(
    segmentTracker,
    campaign,
    visitorId
  );

  const { ahoyAnalyticsTracker } = useAhoyAnalyticsTracker({
    config: {
      ...getAhoyAnalyticsTrackerConfig(env.beaconUrl),
      trackVisits: !isEditPage,
      headers: {
        [process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT || 'X-Snd-Value']:
          process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT_VALUE || 'Sendspark Share',
      },
    },
    debug: env.beaconDebug,
  });

  const { analytics } = useAnalytics({
    campaign: (campaign as unknown) as SharedCampaign,

    analyticsTracker: ahoyAnalyticsTracker,
  });

  async function trackCTAClick() {
    if (isEditPage) return;
    analytics?.track('$cta_click', {
      video: campaign.meta.video,
    });
    await segmentTracker(ANALYTICS_TITLE.CampaignCtaClick, {
      creatorEmail: campaign.creator?.email,
      visitor: visitorId,
      campaignId: campaign.id,
      videoId: campaign.meta.video?.id,
      videoUrl: `${campaign.shareUrl}${campaign.shareId}`,
      videoName: campaign.meta.name,
      creatorId: campaign.creator._id,
      workspace: {
        id: campaign.creator?.workspaceId,
        name: campaign.creator.workspaceName,
      },
      buttonText: campaign?.messages[0]?.text || 'Button Text',
      buttonUrl: campaign?.messages[0]?.link || '',
    });
  }
  const campaignUpdate = useEditPageUpdates(campaign.id, env, setCampaign, campaign.creator?._id, campaign.version);
  let messages = campaign.messages?.find((item) => item.kind === 'link');
  if (messages) {
    messages = { ...messages, view: 'square' };
  }
  const [callToAction, setCallToAction] = useState<CampaignMessage | CampaignMessage[] | undefined>(messages);
  const [showCta, setShowCta] = useState(!!callToAction);

  let titleChanged = campaign.meta.heading1;
  let descriptionChanged = campaign.meta.heading2;
  const router = useRouter();
  if (campaign?.featureFlips?.dynamicVariables) {
    titleChanged = reemplaceVariables(
      campaign.meta.heading1,
      router.query,
      campaign.dynamicVariablesEnable
    );
    descriptionChanged = reemplaceVariables(
      campaign.meta.heading2,
      router.query,
      campaign.dynamicVariablesEnable
    );
    if (!!callToAction && campaign?.messages?.length && campaign.messages[0].text) {
      campaign.messages[0].text = reemplaceVariables(campaign.messages[0]?.text, router.query, campaign.dynamicVariablesEnable)
    }
  }
  const [title, setTitle] = useState(titleChanged);
  const [description, setDescription] = useState(descriptionChanged);

  // async function trackViralLink() {
  //   await segmentTracker(ANALYTICS_TITLE.ViralLinkClick, {
  //     visitor: visitorId,
  //     workspaceName: campaign?.workspaceName,
  //     creator: campaign.creator._id,
  //     creatorEmail: campaign.creator.email,
  //     source: 'View Page',
  //   });
  // }

  useEffect(() => {
    if (!isEditPage) return;

    if (!campaignUpdate) return;

    if (campaignUpdate.cta) setCallToAction(campaignUpdate.cta);

    if (campaignUpdate.style) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        style: {
          ...prevCampaign.style,
          ...campaignUpdate.style,
        },
      }));
    }

    if (typeof campaignUpdate?.title === 'string')
      setTitle(campaignUpdate.title);

    if (typeof campaignUpdate?.message === 'string')
      setDescription(campaignUpdate.message);

    if (typeof campaignUpdate?.ctaActive === 'boolean')
      setShowCta(campaignUpdate.ctaActive);

    setCampaign((prevCampaign) => {
      if (!campaignUpdate.meta) return prevCampaign;
      if (!campaignUpdate?.meta?.muxMP4Ready) return prevCampaign;

      if (campaignUpdate?.meta?.id !== campaign.meta.video?.id) {
        return {
          ...prevCampaign,
          meta: {
            ...prevCampaign.meta,
            video: campaignUpdate.meta,
          },
        };
      }

      return prevCampaign;
    });
  }, [campaign.meta.video?.id, campaignUpdate, isEditPage]);

  const isOracleTheme = initialCampaign?.theme?.name === THEMES.ORACLE;
  const videoBottompadding = !!description || !!showCta;
  const removeDescriptionBottomRadius = !!showCta;
  const hasCalendar = !!campaign?.calendar?.provider && !!campaign?.calendar?.link;

  return (
    <>
      <Layout>
        <Layout.Main>
          <Layout.Left>
            <StyledWrapper>
              <HeadersCustomTheme
                title={campaign.meta.documentTitle}
                campaign={campaign}
                background={style.background}
                backgroundImage={style?.backgroundImage}
                sendsparkBrandingEnabled={campaign.sendsparkBrandingEnabled}
                videoMessageBy={initialCampaign?.theme?.properties?.videoMessageBy}
              />
              <LayoutContent layoutMobileOverides={style?.mobileLayout}>
                <RenderIf
                  condition={!!initialCampaign?.theme?.properties?.style?.bannerSection}
                >
                  <Banner
                    additionalStyles={style?.bannerSection?.container as CSSObject}
                  >
                    <img src={style?.bannerSection?.logo?.url} alt="logo" />
                  </Banner>
                </RenderIf>
                <RenderIf condition={!!campaign?.theme?.properties?.bannerImageUrl}>
                  <HeaderSection>
                    <img
                      src={campaign?.theme?.properties?.bannerImageUrl}
                      alt="banner"
                    />
                  </HeaderSection>
                </RenderIf>
                <MainContent
                  hideBorders={style?.hideMainContentBorders as boolean}
                  background={style?.mainContentBackground}
                >
                  <RenderIf condition={!!title}>
                    <TitleSection
                      additionalStyles={style?.titleSection?.container as CSSObject}
                    >
                      <Title
                        additionalStyles={style?.titleSection?.title as CSSObject}
                        color={style.heading1}
                      >
                        {title}
                      </Title>
                    </TitleSection>
                  </RenderIf>

                  <VideoSection
                    isOracle={isOracleTheme}
                    videoBottomPadding={videoBottompadding}
                    removeBottomRadius={videoBottompadding}
                    additionalStyles={style?.videoSection?.container as CSSObject}
                  >
                    <VideoPlayer
                      isEditPage={isEditPage}
                      campaign={(campaign as unknown) as SharedCampaign}
                      apiV2Url={env.apiV2Url}
                      apiV1Url={environmentsConfig.apiV1Url}
                      muxDataEnv={env.muxDataEnv}
                      muxDataDebug={env.muxDataDebug}
                      nodeEnv={environmentsConfig.nodeEnv}
                      analyticsTracker={ahoyAnalyticsTracker}
                      events={playerEvents}
                    />
                  </VideoSection>

                  <RenderIf condition={!!description}>
                    <DescriptionSection
                      additionalStyles={
                        style?.descriptionSection?.container as CSSObject
                      }
                      mobileStyles={style?.descriptionSection?.mobileContainer}
                      removeBottomRadius={removeDescriptionBottomRadius}
                    >
                      <Description
                        additionalStyles={style?.descriptionSection?.text as CSSObject}
                      >
                        {description}
                      </Description>
                    </DescriptionSection>
                  </RenderIf>

                  <RenderIf condition={showCta}>
                    <ActionSection
                      additionalStyles={style?.actionSection?.container as CSSObject}
                      mobileStyles={style?.actionSection?.mobileContainer}
                    >
                      <CallToAction
                        additionalStyles={style?.actionSection?.ctaButton as CSSObject}
                        borderRadius={style?.actionSection?.buttonBorderRadius}
                        styles={style}
                        message={callToAction as any}
                        trackCTAClick={trackCTAClick}
                      />
                    </ActionSection>
                  </RenderIf>

                  <RenderIf condition={!!campaign?.theme?.properties?.FAQSection}>
                    <QuestionSection>
                      <QuestionTitle>Questions?</QuestionTitle>
                      <QuestionContent color={style.heading2}>
                        <span>Please contact me </span>
                        <span>
                          (
                          <a
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...(isEditPage
                              ? {}
                              : { href: `mailto:${campaign?.creator?.email}` })}
                          >
                            {campaign?.creator?.email}
                          </a>
                          )
                        </span>{' '}
                        <span>your local Roche representative.</span>
                      </QuestionContent>
                    </QuestionSection>
                  </RenderIf>
                </MainContent>
                <RenderIf condition={!!campaign?.sendsparkBrandingEnabled}>
                  <Footer>
                    <VideoMessageSign>
                      Video Message by
                      <LogoLink className="logo-link" target="_blank" rel="noreferrer">
                        <Logo />
                      </LogoLink>
                    </VideoMessageSign>
                  </Footer>
                </RenderIf>
              </LayoutContent>
            </StyledWrapper>
          </Layout.Left>
          <RenderIf condition={hasCalendar}>
            <Layout.Right>
              <OptionalExtraMargin  />
              <Calendar calendar={campaign?.calendar} />
            </Layout.Right>
          </RenderIf>
        </Layout.Main>
      </Layout>
    </>
  );
};

export default CustomTheme;
