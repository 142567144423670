import styled, { css, CSSObject } from 'styled-components';

interface Props {
  additionalStyles?: CSSObject;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: string;
}

const Title = styled.h1<Props>`
  color: ${({ color }) => color || '#333333;'};
  font-family: ${({ fontFamily }) => fontFamily || 'Roboto Bold, san-serif'};
  font-size: ${({ fontSize }) => fontSize || '20px'};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  height: auto;
  justify-content: center;
  line-height: 28px;
  text-align: center;
  word-break: break-word;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

Title.defaultProps = {
  fontWeight: 'bold',
};

export default Title;
