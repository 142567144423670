import styled from 'styled-components';

const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default LogoLink;
