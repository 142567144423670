import styled from 'styled-components';

interface Props {
  color?: string;
}

const QuestionContent = styled.p<Props>`
  // color: ${({ color }) => color || '#333333'};
  color: #333333;
  font-family: Roboto, san-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;

  a {
    color: #0066cc;
  }
  @media screen and (max-width: 500px) {
    span {
      word-break: break-all;
      display: block;
    }
  }
`;

export default QuestionContent;
