import styled from 'styled-components';

const QuestionSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 24px;
  justify-content: center;
`;

export default QuestionSection;
