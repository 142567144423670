import styled, { css, CSSObject } from 'styled-components';

interface Props {
  // TODO: remove padding when roche gets redone
  padding?: string;
  additionalStyles?: CSSObject;
}

const TitleSection = styled.section<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
  width: 100%;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

TitleSection.defaultProps = {
  padding: '24px 24px 0',
};

export default TitleSection;
