import styled from 'styled-components';

const QuestionTitle = styled.section`
  color: #0066cc;
  font-family: 'Roboto Bold', san-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
`;

export default QuestionTitle;
