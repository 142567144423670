import styled, { CSSObject, css } from 'styled-components';

interface Props {
  additionalStyles: CSSObject;
}

const Description = styled.p<Props>`
  font-style: normal;
  line-height: 26px;
  text-align: center;
  word-break: break-word;
  white-space: pre-wrap;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

Description.defaultProps = {
  /* TODO: move Roche default to db */
  additionalStyles: {
    color: '#333333',
    fontFamily: 'Roboto, san-serif',
    fontSize: '17px',
    fontWeight: 'normal',
  },
};

export default Description;
