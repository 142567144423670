import React from 'react';

const SvgTopFlapDown: React.FC = () => (
  <svg
    width="1120"
    height="424"
    viewBox="0 0 1120 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-8.71012e-05 -6.36072e-06C-9.16936e-05 52.5308 26.9729 90.8667 340 293.297C560 435.568 559.999 435.568 779.999 293.297C1093.03 90.8667 1120 52.5309 1120 9.15527e-05L-8.71012e-05 -6.36072e-06Z"
      fill="#CC0000"
    />
    <path
      d="M-8.71012e-05 -6.36072e-06C-9.16936e-05 52.5308 26.9729 90.8667 340 293.297C560 435.568 559.999 435.568 779.999 293.297C1093.03 90.8667 1120 52.5309 1120 9.15527e-05L-8.71012e-05 -6.36072e-06Z"
      fill="black"
      fillOpacity="0.1"
    />
  </svg>
);

export default SvgTopFlapDown;
