import styled from 'styled-components';

const Description = styled.div`
  color: #000000;
  font-family: "CircularXXWeb-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 24px;
`;

export default Description;
