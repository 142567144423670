import { CampaignMessageKind, CampaignMessageSide } from '@interfaces';
import styled, { css } from 'styled-components';

interface MessageStyledProps {
  side?: CampaignMessageSide;
  kind?: CampaignMessageKind;
}

const CallToActionContainer = styled.div<MessageStyledProps>`
  position: relative;
  align-self: ${({ side }) => (side === 'left' ? 'flex-start' : 'flex-end')};
  ${({ kind }) =>
    (kind === 'button' || kind === 'link') &&
    css`
      width: 100%;
    `}
`;

export default CallToActionContainer;
