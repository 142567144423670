import styled from 'styled-components';

const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px 16px 36px;
`;

export default Footer;
