import styled from 'styled-components';

const DescriptionSection = styled.section`
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter UI', sans-serif;
  margin: 32px 0 0;
`;

export default DescriptionSection;
