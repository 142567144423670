import Text404SVG from '@components/Text404SVG';
import Title from '@components/Title';
import ErrorPageLayout from '@components/ErrorPageLayout';
import ErrorMessage from '@components/ErrorMessage';
import Background404Wrapper from '@styles/views/Background404Wrapper';
import ContentWrapper from '@styles/views/ContentWrapper';

const WhiteLabel404Page = () => (
  <ErrorPageLayout>
    <Background404Wrapper>
      <Text404SVG />
    </Background404Wrapper>
    <ContentWrapper>
      <div>
        <Title>We can’t find this page!</Title>
      </div>
      <ErrorMessage>
        Sorry, but it seems this page doesn’t exist anymore.
      </ErrorMessage>
    </ContentWrapper>
  </ErrorPageLayout>
);

export default WhiteLabel404Page;
