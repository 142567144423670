import { useState, useEffect } from 'react';
import { Channel } from 'pusher-js';
import { useAppContext } from '@context/appContext';

interface UseShareReadyProps {
  shareId: string;
  isProcessing: boolean;
}

export const useShareReady = ({
  shareId,
  isProcessing,
}: UseShareReadyProps) => {
  const [shareReady, setShareReady] = useState<Channel | undefined>(undefined);
  const { getPusher } = useAppContext()

  useEffect(() => {
    if (isProcessing) {
      const pusher = getPusher()
      setShareReady(pusher?.subscribe(`share-ready-${shareId}`));
    }
    return () => {
      if (shareReady) {
        shareReady.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareId]);

  return { shareReady };
};
