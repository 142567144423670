import styled from 'styled-components';

const Button = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'CircularXXWeb-Bold', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 10px;
  height: 40px;
  justify-content: center;
  line-height: 24px;
  padding: 8px 24px;
  text-align: center;
  width: 296px;
`;

export const PrimaryButton = styled(Button)`
  background-color: #6140ff;
  color: #f5eeeb;
  gap: 10px;
  margin-bottom: 8px;
  width: 296px;
`;

export const SecondaryButton = styled(Button)`
  border: 1px solid #6140ff;
  color: #6140ff;
  gap: 8px;
  width: 296px;
`;

export default Button;
