import styled from 'styled-components';

const VideoSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Inter UI', sans-serif;
  max-width: 768px;
  position: relative;
  width: 100%;
  /* height: 235px;

  @media (min-width: 768px) {
    height: 433px;
  } */
`;

export default VideoSection;
