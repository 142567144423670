import styled from 'styled-components';

const MainContent = styled.main<{ isEditPage: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  /* padding: ${({ isEditPage }) => (isEditPage ? '0px 16px 36px' : '20px 16px 32px')}; */
  position: relative;
  width: 100%;
`;

export default MainContent;
