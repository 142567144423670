import React from 'react'

export interface SVGReplayProps {
  width?: string;
  height?: string;
  stroke?: string;
  strokeWidth?: string;
}

export const SVGReplay = ({
  width = '16',
  height = '16',
  stroke = "#6140FF",
  strokeWidth = '2',
}: SVGReplayProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.9371 11.5003C2.5517 12.5626 3.43745 13.4484 4.49972 14.063C5.52947 14.6589 6.72509 14.9999 8.00037 14.9999C9.27567 14.9999 10.4713 14.6588 11.5011 14.063C12.5633 13.4483 13.449 12.5626 14.0636 11.5003C14.6594 10.4706 15.0004 9.27506 15.0004 7.99988C15.0004 6.72457 14.6593 5.52892 14.0635 4.49915C13.4488 3.43695 12.5631 2.55124 11.5009 1.93665C10.4712 1.34087 9.27558 0.999878 8.00037 0.999878C6.72518 0.999878 5.52963 1.34085 4.49993 1.93661C3.52036 2.50335 2.25438 3.36207 1.00012 4.25644M1.00012 0.999939V4.25644M5.00012 4.99994H1.00012V4.25644"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)
