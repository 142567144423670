import styled from 'styled-components';

interface LogoProps {
  size: number;
}

const CustomLogo = styled.img<LogoProps>`
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 32px;
  height: ${({ size }) => size}px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

export default CustomLogo;
