/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable */
import { getCampaign } from '@api-v2/campaigns';
import RenderIf from '@components/RenderIf';
import ShareWrappers from '@components/ShareWrappers';
import { CustomTheme } from '@components/Themes';
import WhiteLabel404Page from '@components/WhiteLabel404Page';
import { THEMES } from '@constants/constants';
import { paramsValidator } from '@constants/paramsValidator';
import { Campaign, DynamicVariables, EnvConfigShare } from '@interfaces';
import { NextPageWithLayout } from '@pages/_app';
import GlobalStyle from '@styles/global';
import SharePage from '@styles/views/SharePage';
import VideoProtectedPage from '@styles/views/VideoProtectedPage';
import {
  getDynamicCampaignVariablesTroughParsedQuery,
  getHtmlBackground,
  getJwtToken,
} from '@utils';
import cookie from 'cookie';
import { detect } from 'detect-browser';
import { GetServerSideProps } from 'next';
import { ReactNode, useState } from 'react';

interface ViewCampaignProps {
  campaign: Campaign
  ignoreTracking: boolean
  env: EnvConfigShare
  isLinkedInRequest: boolean
  dynamicVariables: DynamicVariables
  custom404?: boolean
  segmentApiKey?: string
  pusherKey?: string
  pusherCluster?: string
  isProtectedCampaign?: boolean
}

const ViewCampaign: NextPageWithLayout<ViewCampaignProps> = ({
  campaign: initialCampaign,
  ignoreTracking,
  env,
  isLinkedInRequest,
  dynamicVariables,
  isProtectedCampaign
}) => {
  const [campaign, setCampaign] = useState(initialCampaign)
  const [protectedCampaign, setProtectedCampaign] = useState(isProtectedCampaign)
  // const { isOwnerVideo, checkIsOwnerVideo } = useIsOwnerVideo({ campaign, env })

  return (
    <>
      <RenderIf condition={!protectedCampaign}>
        <SharePage
          campaign={campaign}
          ignoreTracking={ignoreTracking}
          env={env}
          isLinkedInRequest={isLinkedInRequest}
          dynamicVariables={dynamicVariables}
          setCampaign={setCampaign}
        />
      </RenderIf>
      <RenderIf condition={!!protectedCampaign}>
        <VideoProtectedPage
          campaign={campaign}
          env={env}
          setCampaign={setCampaign}
          setProtectedCampaign={setProtectedCampaign}
          isLinkedInRequest={isLinkedInRequest}
        />
      </RenderIf>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<
  { campaign?: Campaign; env?: EnvConfigShare; custom404?: boolean },
  { id: string }
> = async ctx => {
  const { params, req, query } = ctx

  const isSendsparkDomain = req?.headers?.host?.includes('sendspark.com')
  const isDevelopment = req?.headers?.host?.includes('localhost')
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
  const isLinkedInRequest = userAgent?.includes('LinkedIn') ?? false
  const browser = detect(userAgent)
  const ignoreTracking = browser?.type !== 'browser'
  const shareId = params?.id
  const paramIsValid = paramsValidator(shareId || '')
  const dynamicVariables = getDynamicCampaignVariablesTroughParsedQuery(query)

  let jwt = '';
  let sendsparkAppRendering = false
  const { 'sec-fetch-site': secFetchSite, 'sec-fetch-dest': secFetchDest, 'cookie': cookieHeader } = ctx.req.headers;
  // Validate is coming from the same site and is an iframe
  if ((secFetchSite === 'same-origin' || secFetchSite === 'same-site') && secFetchDest === 'iframe') {
    if (cookieHeader) {
      const cookies = cookie.parse(cookieHeader);
      jwt = getJwtToken(cookies);
      sendsparkAppRendering = true
    }
  }

  if (!shareId || shareId === 'false' || !paramIsValid) {
    return {
      notFound: true,
    }
  }

  const validationInvalidRequestedProspect = (
    campaign: any,
    dynamicVariables: DynamicVariables
  ) => {
    const { dynamicVideoProps } = campaign
    if (!dynamicVideoProps) {
      return false
    }

    const { contact, customVariables } = dynamicVideoProps
    const prospectVariables: any = { email: contact?.email }
    for (const prospectValue of customVariables as Array<any>) {
      prospectVariables[prospectValue.name] = prospectValue.value
    }

    if (
      Object.keys(dynamicVariables).length > 0 &&
      prospectVariables.email !== dynamicVariables.email
    ) {
      return true
    }

    return false
  }

  let campaign

  try {
    campaign = await getCampaign(shareId, sendsparkAppRendering, req.headers.host, {
      headers: {
        Authorization: jwt,
      }
    })

    const isInvalidRequestedProspect = validationInvalidRequestedProspect(
      campaign,
      dynamicVariables
    )
    if (isInvalidRequestedProspect) {
      return { notFound: true }
    }

  } catch (error: any) {
    if (error?.response?.status === 302) {
      return {
        redirect: {
          destination: error?.response?.data,
          permanent: false,
        },
        props: {},
      }
    }
    if (isSendsparkDomain || isDevelopment) {
      return {
        notFound: true,
      }
    }

    return {
      props: {
        custom404: true,
      },
    }
  }
  // In case calendar FF is off, we need to keep the layout as it was sendspark-legacy
  if (!campaign?.featureFlips?.calendar) {
    campaign = {
      ...campaign,
      theme: {
        ...campaign.theme,
        name: THEMES.SENSDPARK_LEGACY,
      },
    }
  }

  if (campaign?.restricted) {
    return {
      props: {
        campaign,
        isProtectedCampaign: true,
        dynamicVariables,
        env: {
          apiV2Url: process.env.NEXT_PUBLIC_API_V2_URL || '',
          apiV1Url: process.env.NEXT_PUBLIC_API_V1_URL || '',
          nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV || 'development',
          muxDataEnv: process.env.NEXT_PUBLIC_MUX_DATA_ENV || '',
          muxDataDebug: JSON.parse(
            process.env.NEXT_PUBLIC_MUX_DATA_DEBUG || 'false'
          ) as boolean,
          beaconUrl: process.env.NEXT_PUBLIC_BEACON_URL || '',
          beaconDebug: JSON.parse(
            process.env.NEXT_PUBLIC_BEACON_DEBUG || 'false'
          ) as boolean,
          nextPublicSendsparkSite: process.env.NEXT_PUBLIC_SENDSPARK_SITE || '',
          excludedIpForBeaconTracking: JSON.parse(
            process.env.NEXT_PUBLIC_EXCLUDED_IP_FOR_BEACON_TRACKING || '[]'
          ),
          excludedIpForAnalytics: JSON.parse(
            process.env.NEXT_PUBLIC_EXCLUDED_IP_FOR_ANALYTICS || '[]'
          ),
          dashboardUrl: process.env.DASHBOARD_URL || '',
          appUrl: process.env.APP_URL || '',
          editPageUrl: process.env.NEXT_PUBLIC_EDIT_PAGE_URL || '',
          cryptoKey: process.env.NEXT_PUBLIC_CRYPTO_KEY || '',
        },
      }
    }
  }

  return {
    props: {
      campaign,
      ignoreTracking,
      segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || '',
      pusherKey: process.env.NEXT_PUBLIC_PUSHER_KEY || '',
      pusherCluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER || '',
      isLinkedInRequest,
      dynamicVariables,
      env: {
        apiV2Url: process.env.NEXT_PUBLIC_API_V2_URL || '',
        apiV1Url: process.env.NEXT_PUBLIC_API_V1_URL || '',
        nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV || 'development',
        muxDataEnv: process.env.NEXT_PUBLIC_MUX_DATA_ENV || '',
        muxDataDebug: JSON.parse(
          process.env.NEXT_PUBLIC_MUX_DATA_DEBUG || 'false'
        ) as boolean,
        beaconUrl: process.env.NEXT_PUBLIC_BEACON_URL || '',
        beaconDebug: JSON.parse(
          process.env.NEXT_PUBLIC_BEACON_DEBUG || 'false'
        ) as boolean,
        nextPublicSendsparkSite: process.env.NEXT_PUBLIC_SENDSPARK_SITE || '',
        excludedIpForBeaconTracking: JSON.parse(
          process.env.NEXT_PUBLIC_EXCLUDED_IP_FOR_BEACON_TRACKING || '[]'
        ),
        excludedIpForAnalytics: JSON.parse(
          process.env.NEXT_PUBLIC_EXCLUDED_IP_FOR_ANALYTICS || '[]'
        ),
        dashboardUrl: process.env.DASHBOARD_URL || '',
        appUrl: process.env.APP_URL || '',
        videoProcessingGIF: campaign.sources?.thumbnailAnimated ?? '',
        editPageUrl: process.env.NEXT_PUBLIC_EDIT_PAGE_URL || '',
        cryptoKey: process.env.NEXT_PUBLIC_CRYPTO_KEY || '',
      },
    },
  }
}

ViewCampaign.getLayout = (page: ReactNode, pageProps: ViewCampaignProps) => {
  const {
    campaign,
    env,
    custom404,
    segmentApiKey,
    pusherKey = '',
    pusherCluster = '',
  } = pageProps

  const isNormal404 = Object.keys(pageProps).length === 0
  if (custom404 || isNormal404) {
    return (
      <>
        <GlobalStyle background={'#FEFAF5'} />
        <WhiteLabel404Page />
      </>
    )
  }

  if (
    campaign?.theme?.name === THEMES?.ROCHE ||
    campaign?.theme?.name === THEMES?.ORACLE
  ) {
    const htmlBackground =
      campaign?.theme?.properties?.style?.background || '#fff'
    return (
      <ShareWrappers
        htmlBackground={htmlBackground}
        segmentApiKey={segmentApiKey || ''}
        pusherKey={pusherKey}
        pusherCluster={pusherCluster}
      >
        <CustomTheme campaign={campaign} env={env} />
      </ShareWrappers>
    )
  }
  // TODO:  SENDSPARK <- Use this when we create the new structure
  // if (campaign?.theme?.name === THEMES?.SENDSPARK) {
  //   const htmlBackground = isEditPage ? 'transparent' : '#FFFAF4';
  //   console.log({ pageProps })
  //   return (
  //     <ShareWrappers
  //       htmlBackground={htmlBackground}
  //       segmentApiKey={segmentApiKey || ''}
  //       pusherKey={pusherKey}
  //       pusherCluster={pusherCluster}
  //     >
  //       <ThemeV2 props={pageProps}>
  //         {/* {page} */}
  //       </ThemeV2>
  //     </ShareWrappers>
  //   )
  // }

  const htmlBackground = getHtmlBackground(campaign)
  return (
    <>
      <ShareWrappers
        htmlBackground={htmlBackground}
        segmentApiKey={segmentApiKey || ''}
        pusherKey={pusherKey}
        pusherCluster={pusherCluster}
      >
        {page}
      </ShareWrappers>
      <div id="portal-container" />
    </>
  )
}

export default ViewCampaign
