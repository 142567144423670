import styled from 'styled-components';

const CloseButtonStyled = styled.div`
  background: transparent;
  position: absolute;
  right: 23px;
  top: 19px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  opacity: 0.25;

  :hover {
    opacity: 1;
  }
`;

export default CloseButtonStyled;
