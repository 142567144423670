import styled from 'styled-components';
import LogoSvg from '../assets/Logo';

const Logo = styled(LogoSvg)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 32px;
`;

export default Logo;
