import styled from 'styled-components';

const VideoPreview = styled.section<{backgroundColor?: string}>`
  width: 100%;
  aspect-ratio: 16/9;
  background: ${({ backgroundColor }) => backgroundColor || '#F5F5F5'};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & h1 {
      color: white;
      font-size: 20px;
      line-height: 24px;
      margin: 10px 0;
      font-family: Lexend-Medium, 'sans-serif';
      font-weight: 600;
    }

    & p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      font-family: Lexend-Medium, 'sans-serif';
      color: white;
      opacity: 64%;
    }

    & > form {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      & .MuiFormControl-root {
        width: 296px;
        background: white;
        border-radius: 24px;
        margin-right: 8px;

        & .MuiOutlinedInput-root {
          border-radius: 24px;

          & input {
            padding: 16px 20px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            font-family: Lexend-Medium, 'sans-serif';
          }

          & .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
          }
        }
      }

      & button {
        padding: 12px;
        background: rgba(255, 255, 255, 0.32);
        border-radius: 100%;
        min-width: 0;

        svg {
          width: 24px;
          height: 24px;
          color: white;
          
        }

        &:hover {
          background: rgba(255, 255, 255, 0.20);
        }
      }
    }
  }

  & > span {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 8px 0px 0px 0px;
    border-radius: 48px;
    font-size: 14px;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 36px;
    width: 128px;
    white-space: nowrap;

    div {
      display: inline-block;
      margin-top: -14px;
      height: 0;
      width: 37px;
    }

    span {
      vertical-align: middle;
    }
  }

  & > img {
    -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
    filter: blur(8px);
  }

  svg {
    vertical-align: middle;
    width: 44px;
    height: 44px;
    left: 0;
    top: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0 20px;
  }
`;

export default VideoPreview;
