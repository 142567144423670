/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import clsx from 'clsx';

import { SVGReplay } from '@assets/SVGReplay';
import { useViralLink } from '@hooks';
import EndVideoModalStyled from './components/EndVideoModalStyled';
import Title from './components/Title';
import Description from './components/Description';

import DefaultLogo from './components/Logo/DefaultLogo';
import CloseButton from './components/CloseButton';
import { PrimaryButton, SecondaryButton } from './components/Buttons';
import { Campaign } from '@interfaces';
import Logo from './components/Logo/assets/Logo';

interface IEndVideoModalProps {
  campaign: Campaign;
  onClose: () => void;
  handleOnReplay: () => void;
  isOpen: boolean;
}

const EndVideoModal: React.FunctionComponent<IEndVideoModalProps> = ({
  isOpen,
  campaign,
  onClose,
  handleOnReplay,
}) => {
  const viralLink = useViralLink({
    url: `${process.env.NEXT_PUBLIC_SENDSPARK_SITE || ''}/signup`,
    campaignId: campaign?._id,
    referralCode: campaign?.referralCode || '',
    workspaceName: campaign?.workspaceName || '',
    currentSite: 'end of video',
  });

  const handleClose = () => {
    onClose?.();
  };

  const handleStartRecording = () => {
    window.open(viralLink, '_blank');
  };

  return (
    <EndVideoModalStyled className={clsx({ animated: isOpen, hidden: !isOpen })}>
      <CloseButton handleOnClick={handleClose} />
      <DefaultLogo >
        <Logo />
      </DefaultLogo>
      <Title>Send {campaign?.creator?.firstName} a video!</Title>
      <Description>
        Create your own Sendspark account to record and share videos with
        anyone.
      </Description>
      <PrimaryButton onClick={handleStartRecording}>
        Start Recording for Free
      </PrimaryButton>
      <SecondaryButton onClick={handleOnReplay}>
        <SVGReplay />
        Replay Video
      </SecondaryButton>
    </EndVideoModalStyled>
  );
};

export default EndVideoModal;
