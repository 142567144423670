import { createGlobalStyle } from 'styled-components';

interface Props {
  background?: string;
  backgroundImage?: string;
}

const BodyBackground = createGlobalStyle<Props>`
  body {
    min-height: 100vh;
    height: 100%;
    background: ${({ backgroundImage }) =>
      backgroundImage
        ? `url(${backgroundImage})no-repeat center center fixed`
        : ''};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: ${({ background }) => background};
  }
`;

BodyBackground.defaultProps = {
  background: '#ffffff',
};

export default BodyBackground;
