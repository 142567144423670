import { FunctionComponent } from 'react';
import Head from 'next/head';

import RenderIf from '@components/RenderIf';
import { Campaign } from '@interfaces';
import OpenGraph from '@components/Layout/components/OpenGraph';
import BodyBackground from '@components/Themes/Custom/Layout/styles/BodyBackground';
import EmbedGlobalStyle from '@components/Layout/styles/EmbedGlobalStyle';

type Props = {
  campaign: Campaign;
  title?: string;
  background?: string;
  backgroundImage?: string;
  sendsparkBrandingEnabled?: boolean;
  embed?: boolean;
  videoMessageBy?: boolean;
};

const HeadersCustomTheme: FunctionComponent<Props> = ({
  campaign,
  title = '',
  background,
  sendsparkBrandingEnabled,
  embed = false,
  backgroundImage,
}: Props) => (
  <>
    <BodyBackground
      background={background}
      backgroundImage={backgroundImage}
    />
    <OpenGraph
      campaign={campaign}
    />
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin=""
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap"
        rel="stylesheet"
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Regular.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Regular.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Bold.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Inter/Inter-Bold.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Medium.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Medium.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit Bold.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit Bold.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Regular.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Regular.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Light.otf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/Larsseit/Type Dynamic - Larsseit-Light.ttf"
        as="font"
        crossOrigin=""
      />
      <link
        rel="shortcut icon"
        type="image/png"
        href={
          sendsparkBrandingEnabled
            ? '/assets/favicon/favicon.png'
            : '/assets/alternative-favicon/sendspark-white-label-favicon.png'
        }
      />
      <meta name="robots" content="noindex" />
    </Head>
    <RenderIf condition={embed}>
      <EmbedGlobalStyle />
    </RenderIf>
  </>
);

export default HeadersCustomTheme;
