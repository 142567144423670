import styled from 'styled-components';

const Title = styled.div`
  color: #000000;
  font-family: "CircularXXWeb-Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 8px;
`;

export default Title;
