import { FC, MouseEventHandler } from 'react';

interface Props {
  className?: string;
  onClick?: MouseEventHandler;
}

const SpinnerIconSvg: FC<Props> = ({ className, onClick }) => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88Z" />
    <path
      opacity="0.3"
      d="M44 60C52.8366 60 60 52.8366 60 44C60 35.1634 52.8366 28 44 28C35.1634 28 28 35.1634 28 44C28 52.8366 35.1634 60 44 60Z"
      stroke="white"
      strokeWidth="4"
    />
    <path
      d="M60 44C60 35.1634 52.8366 28 44 28"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export default SpinnerIconSvg;
