import React from 'react';
import SvgTopFlapUp from '@assets/SvgTopFlapUp';
import SvgTopFlapDown from '@assets/SvgTopFlapDown';
import SvgInsideEnvelope from '@assets/SvgInsideEnvelope';
import SvgFrontFlaps from '@assets/SvgFrontFlaps';
import SvgBackEnvelope from '@assets/SvgBackEnvelope';
import {
  EnvelopeContainer,
  Envelope,
  EnvelopeFront,
  EnvelopeBack,
  TopFlapOpen,
  TopFlapClose,
  VideoContainer,
  FlapsBody,
  BackFace,
  Throat,
  Root,
} from './styles';

export interface IntroAnimationContainerProps {
  children: React.ReactNode;
  name?: string;
}

// The default intro animation for the application, which is no animation at all.
const DefaultIntroAnimation = ({ children }: IntroAnimationContainerProps) => (
  <>{children}</>
);

export default DefaultIntroAnimation;

export const EnvelopeIntroAnimation = ({
  children,
  name
}: IntroAnimationContainerProps) => (
  <Root>
    <EnvelopeContainer>
      <Envelope>
        <EnvelopeFront>
          <TopFlapOpen className="svg-parent">
            <SvgTopFlapUp />
          </TopFlapOpen>
          <TopFlapClose className="svg-parent">
            <SvgTopFlapDown />
          </TopFlapClose>
          <FlapsBody className="svg-parent">
            <SvgFrontFlaps />
          </FlapsBody>
          <Throat className="svg-parent">
            <SvgInsideEnvelope />
          </Throat>
          <VideoContainer>{children}</VideoContainer>
        </EnvelopeFront>
        <EnvelopeBack name={name}>
          <BackFace className="svg-parent">
            <SvgBackEnvelope />
          </BackFace>
        </EnvelopeBack>
      </Envelope>
    </EnvelopeContainer>
  </Root>
);
