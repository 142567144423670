import styled from 'styled-components';

const OptionalExtraMargin = styled.div`
  margin-top: 128px;

  @media screen and (max-width: 1200px) {
    margin: 0;
  }
`;

export default OptionalExtraMargin;
