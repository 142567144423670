import { BannerStyles } from './styles'

function Banner({text, textButton, link}: any) {
  return (
    <BannerStyles>
      { text }
      {' '} 
      <a target="_blank" href={link}>{ textButton }</a>
    </BannerStyles>
  )
}

export default Banner