import * as React from 'react';

import theme from '@styles/theme';
import GlobalStyle from '@styles/global';
import Segment from '@components/segment.io';
import { SnackbarProvider } from 'notistack';
import { AppProvider } from '@context/appContext';
import styled, { ThemeProvider } from 'styled-components';

interface IShareWrappersProps {
  htmlBackground: string;
  segmentApiKey: string;
  pusherKey: string;
  pusherCluster: string;
  children: React.ReactNode;
}
const StyledSnackbarProvider = styled(SnackbarProvider)`
  @media (max-width: 600px) {
    max-width: fit-content;
    margin: auto;
  }
`;
const ShareWrappers: React.FunctionComponent<IShareWrappersProps> = ({
  htmlBackground,
  segmentApiKey,
  pusherKey,
  pusherCluster,
  children
}) => {

  return (
    <>
      <GlobalStyle background={htmlBackground} />
      <Segment segmentApiKey={segmentApiKey} />
      <StyledSnackbarProvider
        dense
        autoHideDuration={3000}
        maxSnack={1}
        style={{
          fontSize: 16,
          justifyContent: 'center',
        }}
        anchorOrigin={
          {
            vertical: 'top',
            horizontal: 'center'
          }
        }
      >
        <AppProvider pusherKey={pusherKey} pusherCluster={pusherCluster}>
          <ThemeProvider theme={theme}>
            {children}
          </ThemeProvider>
        </AppProvider>
      </StyledSnackbarProvider>
    </>
  );
};

export default ShareWrappers;
