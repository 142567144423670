import React from 'react';

const SvgInsideEnvelope: React.FC = () => (
  <svg
    width="1120"
    height="720"
    viewBox="0 0 1120 720"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_97)">
      <rect width="1120" height="720" fill="#CC0000" />
      <rect width="1120" height="720" fill="black" fillOpacity="0.15" />
    </g>
    <defs>
      <clipPath id="clip0_1_97">
        <rect width="1120" height="720" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgInsideEnvelope;
