import { getCampaign } from '@api-v2/campaigns'
import Banner from '@components/Banner'
import Calendar from '@components/Calendars'
import CallToAction from '@components/CallToAction'
import Layout from '@components/CompoundLayout'
import {
  DescriptionWrapper,
  StyledButtons,
  StyledContent,
  StyledTitle,
  StyledVideoPlayer,
  StyledWrapper,
} from '@components/CompoundLayout/styles'
import LegacyAndV2 from '@components/CustomLayouts/LegacyAndV2'
import EndVideoModal from '@components/EndVideoModal'
import Headers from '@components/Headers'
import Footer from '@components/Layout/styles/Footer'
import LogoFooter from '@components/Layout/styles/Logo'
import LogoLink from '@components/Layout/styles/LogoLink'
import VideoMessageSign from '@components/Layout/styles/VideoMessageSign'
import Logo from '@components/Logo'
import NavBar from '@components/NavBar'
import RenderIf from '@components/RenderIf'
import RenderIfOwner from '@components/RenderIfOwner'
import ShowMoreTextContainer from '@components/ShowMoreTextContainer'
import SpinnerIcon from '@components/VideoPlayer/components/Scene/styles/SpinnerIcon'
import { environmentsConfig } from '@config'
import { ANALYTICS_TITLE, CALENDAR_CUSTOM_LAYOUTS, MUX_STATUS, THEMES } from '@constants/constants'
import { SegmentEvents } from '@constants/SegementEvents'
import {
  useCreateIntegrationsByPosition,
  useEditPageUpdates,
  useIsEditPage,
  useLocalStorage,
  useSegmentTracker,
  useViralLink,
  useVisitorId,
} from '@hooks'
import { usePlayerTrackingEvents } from '@hooks/lib/usePlayerTrackingEvents'
import { useShareReady } from '@hooks/lib/useShareReady'
import useIsOwnerVideo from '@hooks/useIsOwnerVideo'
import useTopToastNotification from '@hooks/useTopToastNotification'
import { Backdrop } from '@material-ui/core'
import { Campaign, CampaignMessage, DynamicVariables, EnvConfigShare, PlayerAnimation } from '@interfaces'
import { NextPageWithLayout } from '@pages/_app'
import {
  getAhoyAnalyticsTrackerConfig,
  useAhoyAnalyticsTracker,
  useAnalytics,
} from '@sendspark/analytics'
import { Campaign as SharedCampaign } from '@sendspark/types'
import {
  getDynamicCampaignVariables,
  getIp,
  reemplaceVariables,
  removeQueryParamsFromRouter,
  validateAndParseCustomCTAUrl,
} from '@utils/index'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import ActionSection from './ActionSection'
import Description from './Description'
import DescriptionSection from './DescriptionSection'
import MainContent from './MainContent'
import Title from './Title'
import TitleSection from './TitleSection'
import VideoPreview from './VideoPreview'
import VideoSection from './VideoSection'
import DefaultIntroAnimation, { EnvelopeIntroAnimation } from './intro-animation'

const Player = dynamic(() => import('@components/Player'), { ssr: false })

interface VideoSharePusherData {
  payload: {
    videoProcessing: boolean
    unbind: boolean
  }
}

interface ViewCampaignProps {
  campaign: Campaign
  ignoreTracking: boolean
  env: EnvConfigShare
  isLinkedInRequest: boolean
  dynamicVariables: DynamicVariables
  custom404?: boolean
  segmentApiKey?: string
  pusherKey?: string
  pusherCluster?: string
  isProtectedCampaign?: boolean
  setCampaign: Dispatch<SetStateAction<Campaign>>
}

const SharePage: NextPageWithLayout<ViewCampaignProps> = ({
  campaign,
  ignoreTracking,
  env,
  isLinkedInRequest,
  dynamicVariables,
  setCampaign,
}) => {
  const { checkIsOwnerVideo, isOwnerVideo } = useIsOwnerVideo({ campaign, env })
  const isEditPage = useIsEditPage()

  const handleCopyLink = async (): Promise<void> => {
    const viewUrl = `${campaign?.shareUrl}${campaign?.shareId}`
    try {
      await navigator.clipboard.writeText(viewUrl)
      notification('Video link has been copied!')
    } catch (err) {
      console.error('Failed to copy text: ', err)
      notification('Failed to copy link!')
    }
  }

  const handleDownload = async (): Promise<void> => {
    try {
      const { meta: { video, heading1, name } } = campaign
  
      const isMp4Ready = video?.muxMP4Ready || false
      const mp4Url = video?.sources?.find(source => source.type === 'video/mp4')?.src
      const videoName = heading1 || name || video?.name || ''
  
      const isReadyToDownload = isMp4Ready && mp4Url && videoName
  
      if (!isReadyToDownload) {
        notification('Video is not ready for download')
        return
      }
  
      const encodedVideoName = encodeURIComponent(videoName)
      const downloadUrl = `${mp4Url}?download=${encodedVideoName}`
      window.open(downloadUrl, '_self')
      notification('Your video is downloading!')
      return
    } catch (error) {
      console.error('Failed to download video: ', error)
      notification('Failed to download video!')
    }
  }

  const [
    prospectTrackVariables,
    setProspectTrackVariables,
  ] = useState<DynamicVariables>(dynamicVariables)
  const isV2Theme = campaign?.theme?.name === THEMES?.SENDSPARK
  const { shareReady } = useShareReady({
    shareId: campaign?.shareId || '',
    isProcessing:
      campaign?.meta?.video?.muxStatus === MUX_STATUS.PREPARING || false,
  })
  const { notification } = useTopToastNotification()
  const { style } = campaign
  const campaignCreatorWorkspaceId = campaign.creator?.workspaceId
  const messages = campaign?.messages || [];
  const isMultipleCTAsSupported = campaign?.featureFlips?.supportMultipleCTAs || false;

  const segmentTracker = useSegmentTracker(
    env.apiV2Url,
    campaignCreatorWorkspaceId,
    env.cryptoKey
  )
  const visitorId = useVisitorId()

  const campaignUpdate = useEditPageUpdates(
    campaign.id,
    env,
    setCampaign,
    campaign.creator?._id,
    campaign.version
  )
  
  const [callToAction, setCallToAction] = useState<CampaignMessage | CampaignMessage[]>(
    isMultipleCTAsSupported 
      ? messages.filter(item => item.kind === 'link') as CampaignMessage[] 
      : messages.find(item => item.kind === 'link') as CampaignMessage
  )
  const [showCta, setShowCta] = useState(!!callToAction)
  const [showViralLoop, setShowViralLoop] = useState(false)
  const [replayVideo, setReplayVideo] = useState(false)
  const playerEvents = usePlayerTrackingEvents(
    segmentTracker,
    campaign,
    visitorId,
    setShowViralLoop,
    prospectTrackVariables
  )

  let titleChanged = campaign.meta?.heading1
  let descriptionChanged = campaign.meta?.heading2

  const [analitycsIdentityValue, setAnalitycsIdentityValue] = useLocalStorage(
    'ahoyData',
    {}
  )
  const [anonymousId] = useLocalStorage('ajs_anonymous_id', '')
  const [analitycsCrmValue, setAnalitycsCrmValue] = useLocalStorage(
    'crmData',
    {}
  )
  const [
    analitycsIdentityValueUserLogged,
    setAnalitycsIdentityValueUserLogged,
  ] = useLocalStorage('ahoyDataUserLogged', {})

  const router = useRouter()
  if (
    campaign?.featureFlips?.dynamicVariables &&
    campaign.dynamicVariablesEnable
  ) {
    titleChanged = reemplaceVariables(
      campaign.meta?.heading1,
      router.query,
      campaign.dynamicVariablesEnable
    )
    descriptionChanged = reemplaceVariables(
      campaign.meta?.heading2,
      router.query,
      campaign.dynamicVariablesEnable
    )

    if (!!callToAction && campaign?.messages?.length) {
      const messages = campaign.messages.map((message: CampaignMessage) => {
        if (!message?.text) return message

        message.text = reemplaceVariables(
          message.text,
          router.query,
          campaign.dynamicVariablesEnable
        )

        return message
      })

      campaign.messages = messages
    }
  }

  if (campaign?.dynamicVideoProps?.customVariables) {
    if (!!callToAction && campaign?.messages?.length) {
      const messages = campaign.messages.map((message: CampaignMessage) => {
        if (!message?.link) return message

        message.link = validateAndParseCustomCTAUrl(
          message.link,
          campaign?.dynamicVideoProps?.customVariables as any
        )

        return message
      })

      campaign.messages = messages
    }
  }
  useEffect(() => {
    console.log('dynamicVariables', dynamicVariables)
    const dynamicVariableKeys = Object.keys(dynamicVariables)

    if (!dynamicVariableKeys.length) return

    removeQueryParamsFromRouter(router, dynamicVariableKeys)
  }, [])

  const [title, setTitle] = useState(titleChanged)
  const [description, setDescription] = useState(descriptionChanged)
  const [pageTitle, setPageTitle] = useState<string>()

  const getPageTitle = useCallback((campaign: Campaign) => {
    const branding = campaign?.sendsparkBrandingEnabled
      ? 'Sendspark'
      : campaign?.workspaceName
    const splitCharacter = titleChanged ? '|' : ''
    return `${branding} ${splitCharacter} ${titleChanged}`.substring(0, 160)
  }, [])

  const saveSendsparkUserLoggedBeforeOverride = () => {
    if (!!(analitycsIdentityValue as any).userId) {
      setAnalitycsIdentityValueUserLogged(analitycsIdentityValue)
    }
  }

  const recoveryUserLoggedIfExist = () => {
    if ((analitycsIdentityValueUserLogged as any).userId) {
      setAnalitycsIdentityValue(analitycsIdentityValueUserLogged)
      setAnalitycsIdentityValueUserLogged({})
    }
  }

  useEffect(() => {
    if (campaign?.featureFlips?.dynamicVariables && dynamicVariables)
      setProspectTrackVariables(dynamicVariables)

    if (campaign?.dynamicVideoProps) {
      const variables: DynamicVariables = {
        email: campaign?.dynamicVideoProps?.contact?.email,
      }

      campaign?.dynamicVideoProps?.customVariables?.forEach(variable => {
        if (variable.value) variables[variable.name] = variable.value
      })

      setProspectTrackVariables(variables)
    }
  }, [dynamicVariables, campaign?.dynamicVideoProps])

  useEffect(() => {
    const shareId = campaign?.shareId
    if (shareReady && shareId) {
      // eslint-disable-next-line no-underscore-dangle
      const updateCampaignDataWhenVideoShareIdIsPrepared = async (
        data: VideoSharePusherData
      ) => {
        if (data?.payload?.videoProcessing === false) {
          const fetchedCampaign = await getCampaign(shareId, undefined, undefined, {
            baseURL: env.apiV2Url,
          })
          setCampaign(fetchedCampaign)
        }

        // unbind event
        if (data?.payload?.unbind) {
          shareReady?.unbind(
            shareId,
            updateCampaignDataWhenVideoShareIdIsPrepared
          )
        }
      }
      shareReady.bind(shareId, updateCampaignDataWhenVideoShareIdIsPrepared)
    }
  }, [shareReady])

  const createAndSetAnalitycsCrmValue = (crmDataToMap: object): void => {
    // First that all we need to filter the crm data
    const isInvalidValue = /[!#$%^*()_+\-=\\[\]{};:"\\|/?~]/
    let crmData = {} as any

    for (const [key, value] of Object.entries(crmDataToMap)) {
      if (!isInvalidValue.test(value as string)) {
        crmData[key] = value
      }
    }
    // If Sendspark user exist we save this data to recovery when user is not identify by params
    saveSendsparkUserLoggedBeforeOverride()

    // Using name if exists or fistname if exist if not we take as name the part of email before @
    const validName =
      crmData?.name && crmData?.name !== '' ? crmData.name : null
    const name =
      validName ??
      crmData?.firstname ??
      (crmData?.email as string)?.split('@')[0] ??
      crmData?.email

    // We override user identity using data provided by params
    analitycsCrmValue
    setAnalitycsCrmValue({
      name: name,
      email: crmData?.email?.toLowerCase(),
      lastName: crmData?.lastname,
      companyName: crmData?.companyname,
    })
  }

  useEffect(() => {
    if (campaign?.featureFlips?.dynamicVariables) {
      if (
          campaign?.featureFlips?.dynamicsExtraFields &&
          campaign?.dynamicVideoProps?.customVariables
        ) {
        const contact: Record<string, string> = {}
        campaign?.dynamicVideoProps?.customVariables?.forEach((k: {name: string, value: string}) => {
          contact[k.name] = k.value
        })
        createAndSetAnalitycsCrmValue(contact)
      } else if (campaign?.dynamicVideoProps?.contact) {
        const { contact } = campaign.dynamicVideoProps
        createAndSetAnalitycsCrmValue(contact)
      } else if (
        dynamicVariables?.email ||
        dynamicVariables?.firstname ||
        dynamicVariables?.lastname ||
        dynamicVariables?.companyname
      ) {
        createAndSetAnalitycsCrmValue(dynamicVariables)
      } else {
        // We recovery Sendspark user data because
        recoveryUserLoggedIfExist()
      }
    }

    setPageTitle(getPageTitle(campaign))

    return () => {
      setAnalitycsIdentityValue({})
    }
  }, [])

  const { ahoyAnalyticsTracker } = useAhoyAnalyticsTracker({
    config: {
      ...getAhoyAnalyticsTrackerConfig(env.beaconUrl),
      trackVisits: !isEditPage,
      headers: {
        [process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT || 'X-Snd-Value']:
          process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT_VALUE || 'Sendspark Share',
      },
    },
    debug: env.beaconDebug,
  })

  const { analytics } = useAnalytics({
    campaign: (campaign as unknown) as SharedCampaign,

    analyticsTracker: ahoyAnalyticsTracker,
  })

  const trackCTAClick = async () => {
    if (isEditPage || isOwnerVideo) return
    analytics?.track('$cta_click', {
      video: campaign.meta.video,
    })

    await segmentTracker(ANALYTICS_TITLE.CampaignCtaClick, {
      creatorEmail: campaign.creator?.email,
      visitor: visitorId,
      campaignId: campaign.id,
      videoId: campaign.meta.video?.id,
      videoUrl: `${campaign.shareUrl}${campaign.shareId}`,
      videoName: campaign.meta.name,
      workspace: {
        id: campaign.creator?.workspaceId,
        name: campaign.creator?.workspaceName,
      },
      buttonText: campaign?.messages[0]?.text || 'Button Text',
      buttonUrl: campaign?.messages[0]?.link || '',
      dynamicVariables: prospectTrackVariables,
      creatorId: campaign.creator?._id,
    })

    await segmentTracker(ANALYTICS_TITLE.CtaCovensionGot, {
      creatorEmail: campaign.creator?.email,
      campaignId: campaign.id,
      viewerId:
        (analitycsIdentityValue as any)?.userId || anonymousId || visitorId,
      shareUrl: window.location.href,
      ctaText: campaign?.messages[0]?.text || '',
      ctaUrl: campaign?.messages[0]?.link || '',
      workspace: {
        id: campaign.creator?.workspaceId,
        name: campaign.creator?.workspaceName,
      },
      creatorId: campaign.creator?._id,
    })
  }

  async function trackViralLink() {
    if (isEditPage) return
    await segmentTracker(ANALYTICS_TITLE.ViralLinkClick, {
      workspaceName: campaign?.workspaceName,
      creator: campaign.creator?._id,
      creatorEmail: campaign.creator?.email,
      source: 'View Page',
      visitor: visitorId,
      workspace: {
        id: campaign.creator?.workspaceId,
        name: campaign.creator?.workspaceName,
      },
    })
  }

  useEffect(() => {
    if (campaign?.userIntegrations?.length > 0) {
      useCreateIntegrationsByPosition(campaign.userIntegrations)
    }
  }, [campaign?.userIntegrations?.length])

  useEffect(() => {
    if (!isEditPage) return

    if (!campaignUpdate) return
    if (campaignUpdate.cta) setCallToAction(campaignUpdate.cta)

    if (campaignUpdate.style) {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        style: {
          ...prevCampaign.style,
          ...campaignUpdate.style,
        },
      }))
    }

    if (typeof campaignUpdate?.title === 'string')
      setTitle(campaignUpdate.title)

    if (typeof campaignUpdate?.message === 'string')
      setDescription(campaignUpdate.message)

    if (typeof campaignUpdate?.ctaActive === 'boolean')
      setShowCta(campaignUpdate.ctaActive)

    setCampaign(prevCampaign => {
      if (!campaignUpdate.meta) return prevCampaign
      if (!campaignUpdate?.meta?.muxMP4Ready) return prevCampaign

      if (campaignUpdate?.meta?.id !== campaign.meta.video?.id) {
        return {
          ...prevCampaign,
          meta: {
            ...prevCampaign.meta,
            video: campaignUpdate.meta,
          },
        }
      }
      if (!campaignUpdate?.calendar) {
        return {
          ...prevCampaign,
          calendar: undefined,
        }
      }
      if (
        campaignUpdate.calendar?.provider !== campaign.calendar?.provider ||
        campaignUpdate.calendar?.link !== campaign.calendar?.link
      ) {
        return {
          ...prevCampaign,
          calendar: {
            ...prevCampaign.calendar,
            ...campaignUpdate.calendar,
          },
        }
      }

      return prevCampaign
    })
  }, [campaign.meta.video?.id, campaignUpdate, isEditPage])

  useEffect(() => {
    checkIsOwnerVideo()
    const trackingView = async () => {
      const ip = await getIp()

      // block linkedin prefetch from triggering analytics
      if (isLinkedInRequest || env.excludedIpForAnalytics?.includes(ip)) return

      await segmentTracker(ANALYTICS_TITLE.CampaignView, {
        creatorEmail: campaign.creator.email,
        visitor: visitorId,
        campaignId: campaign.id,
        videoId: campaign.meta.video?.id,
        workspace: {
          id: campaign.creator.workspaceId,
          name: campaign.creator.workspaceName,
        },
        dynamicVariables: prospectTrackVariables,
        creatorId: campaign.creator._id,
      })

      await segmentTracker(ANALYTICS_TITLE.ViewGot, {
        viewerId:
          (analitycsIdentityValue as any)?.userId || anonymousId || visitorId,
        shareUrl: window.location.href,
        workspace: {
          id: campaign.creator.workspaceId,
          name: campaign.creator.workspaceName,
        },
        creatorId: campaign.creator._id,
      })
      
      await segmentTracker(ANALYTICS_TITLE.VideoViewReceived, {
        viewerId:
          (analitycsIdentityValue as any)?.userId || anonymousId || visitorId,
        shareUrl: window.location.href,
        workspace: {
          id: campaign.creator.workspaceId,
          name: campaign.creator.workspaceName
        },
        creatorId: campaign.creator._id
      })

      if (campaign?.featureFlips?.dynamicVariables) {
        const variables: string[] = getDynamicCampaignVariables(
          campaign.meta.heading1 + campaign.meta.heading2
        )
        if (!!variables.length) {
          await segmentTracker(SegmentEvents.dynamicCampaignViewed, {
            dynamicVariables: {
              query: { ...router.query, ...dynamicVariables },
              text: [...variables],
            },
            campaignId: campaign.id,
            creator: campaign.creator.email,
            url: window.location.href,
            workspace: {
              id: campaign.creator.workspaceId,
              name: campaign.creator.workspaceName,
            },
            creatorId: campaign.creator._id,
          })
        }
      }
      if (
        !isEditPage &&
        !campaignUpdate &&
        analytics &&
        !ignoreTracking &&
        !env.excludedIpForBeaconTracking?.includes(ip) &&
        !isOwnerVideo
      ) {
        if (analytics) {
          try {
            analytics?.track('$visit', { video: campaign?.meta?.video });
          } catch (error) {
            console.error('Error tracking visit', error);
          }
        }
      }
    }

    if (!isEditPage && !campaignUpdate && analytics && !ignoreTracking) {
      // eslint-disable-next-line no-void
      void trackingView()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics])

  useEffect(() => {
    checkIsOwnerVideo()
  }, [campaign])

  const handleOnReplay = () => {
    setShowViralLoop(false)
    setReplayVideo(true)
  }

  // Check if viral modal should be open
  const isViralModalOpen =
    !isEditPage && // Not avaiable on edit page
    !!campaign?.sendsparkBrandingEnabled && // Visible on paid plan and branding is enabled
    !(analitycsIdentityValue as any).userId && // Not visible for logged in users
    (campaign?.isFreeWorkspace || false) && // Not visible for paid plans
    showViralLoop

  const viralLink = useViralLink({
    campaignId: campaign?._id,
    referralCode: campaign?.referralCode || '',
    workspaceName: campaign?.workspaceName,
    currentSite: 'share page',
  })

  // const handleCopyLink = async (): Promise<void> => {
  //   const viewUrl = `${campaign?.shareUrl}${campaign?.shareId}`
  //   try {
  //     await navigator.clipboard.writeText(viewUrl)
  //     notification('Video link has been copied!')
  //   } catch (err) {
  //     console.error('Failed to copy text: ', err)
  //     notification('Failed to copy link!')
  //   }
  // }
  const hasCalendar =
    !!campaign?.calendar?.provider && !!campaign?.calendar?.link

  
  const calendarCustomStyles = hasCalendar ? CALENDAR_CUSTOM_LAYOUTS[campaign?.calendar?.provider as string] : false

  const CustomLayout = useMemo(() => {
    if (!calendarCustomStyles) return Layout;

    const LayoutObject = { ...Layout };

    Object.entries(calendarCustomStyles).forEach(([key, value]) => {
      if (Layout[value]) {
        LayoutObject[key] = Layout[value];
      }
    });

    return LayoutObject;
  }, [calendarCustomStyles]);

  const isEnvelopeAnimation = campaign.theme?.properties?.player?.animation === PlayerAnimation.ENVELOPE
  const IntroAnimationContainer = isEnvelopeAnimation ? EnvelopeIntroAnimation : DefaultIntroAnimation

  if (isV2Theme) {
    return (
      <Layout
        backgroundImage={campaign.theme?.properties?.style?.backgroundImage}
        backgroundGradient={campaign.theme?.properties?.style?.backgroundGradient}
      >
        <RenderIfOwner
          cryptoKey={env.cryptoKey || ''}
          isOwnerVideo={isOwnerVideo}
        >
          <Banner
            text="This is a preview. Your view will not show in analytics."
            textButton="Edit Video"
            link={`${env.editPageUrl}${campaign?.id}`}
          />
        </RenderIfOwner>
        <Headers
          title={pageTitle}
          campaign={campaign}
          sendsparkBrandingEnabled={campaign.sendsparkBrandingEnabled}
          isLinkedInRequest={isLinkedInRequest}
        />
        {/* <RenderIf condition={!isEditPage}> */}
        <CustomLayout.Top>
          <NavBar
            background={style?.background}            
            border={{ color: style?.borderColor }}
            icon={{ color: style?.iconColor }}
            url={campaign.meta.logo?.url}
            // isOwnerVideo={isOwnerVideo}
            // handleOnEdit={handleEdit}
            handleOnDownload={handleDownload}
            handleOnCopyLink={handleCopyLink}
            isNCSU={campaign?.featureFlips?.ncsuProject}
            navbarThemeStyle={campaign.theme?.properties?.style?.navbar}
          />
        </CustomLayout.Top>
        {/* </RenderIf> */}
        <CustomLayout.Main>
          <CustomLayout.Left>
            <StyledWrapper>
            <IntroAnimationContainer
            name={dynamicVariables?.firstname || campaign?.dynamicVideoProps?.contact?.name || 'Watch Video'}
            >
              <StyledVideoPlayer>
                <RenderIf
                  condition={
                    campaign?.meta?.video?.muxStatus === MUX_STATUS.READY ||
                    (campaign?.meta?.video?.uploadingStatus === 'pending' &&
                      campaign?.meta?.video?.muxStatus === MUX_STATUS.PREPARING)
                  }
                >
                  <Player
                    isEditPage={isEditPage}
                    campaign={(campaign as unknown) as SharedCampaign}
                    apiV2Url={env.apiV2Url}
                    muxDataEnv={env.muxDataEnv}
                    muxDataDebug={env.muxDataDebug}
                    analyticsTracker={
                      isOwnerVideo ? (null as any) : ahoyAnalyticsTracker
                    }
                    events={isOwnerVideo ? (null as any) : playerEvents}
                    apiV1Url={environmentsConfig.apiV1Url}
                    nodeEnv={environmentsConfig.nodeEnv}
                    autoPlay={
                      campaign?.featureFlips?.autoPlay && campaign?.autoPlay
                    }
                    thumbsUp={
                      campaign?.featureFlips?.thumbsUp && campaign?.thumbsUp
                    }
                    gapCombinedVideoPlayer={
                      campaign?.featureFlips?.gapCombinedVideoPlayer &&
                      campaign?.gapCombinedVideoPlayer
                    }
                    replayVideo={replayVideo}
                    setReplayVideo={setReplayVideo}
                  />
                </RenderIf>
                <RenderIf
                  condition={
                    ['uploading', 'completed'].includes(
                      campaign?.meta?.video?.uploadingStatus || ''
                    ) &&
                    campaign?.meta?.video?.muxStatus === MUX_STATUS.PREPARING
                  }
                >
                  <VideoPreview>
                    <span>
                      <div>
                        <SpinnerIcon spinnerBackgroundColor="#00000000" />
                      </div>
                      <span>Processing...</span>
                    </span>
                    <img src={env?.videoProcessingGIF} alt="" />
                  </VideoPreview>
                </RenderIf>
              </StyledVideoPlayer>
            </IntroAnimationContainer>
            <StyledContent animated={isEnvelopeAnimation}>
              <RenderIf condition={!!title}>
                <StyledTitle color={style.heading1}>{title}</StyledTitle>
              </RenderIf>
              {/* Description */}
              <RenderIf condition={!!description}>
                <DescriptionWrapper>
                  <ShowMoreTextContainer
                    color={style.heading2}
                    text={description}
                    maxLength={180}
                    showMoreColor={style.responseButtonBackground}
                  />
                </DescriptionWrapper>
              </RenderIf>
              {/* Call-to-actions */}
              <RenderIf condition={showCta}>
                <StyledButtons>
                  <CallToAction
                    isV2Theme={isV2Theme}
                    styles={style}
                    message={callToAction}
                    trackCTAClick={trackCTAClick}
                    isMultipleCTAsSupported={isMultipleCTAsSupported}
                  />
                </StyledButtons>
              </RenderIf>
              </StyledContent>
            </StyledWrapper>
          </CustomLayout.Left>
          {/* Calendar */}
          <RenderIf condition={hasCalendar}>
            <CustomLayout.Right>
              <Calendar calendar={campaign?.calendar} />
            </CustomLayout.Right>
          </RenderIf>
        </CustomLayout.Main>
        <RenderIf condition={!!campaign?.sendsparkBrandingEnabled}>
          <CustomLayout.Footer>
              <VideoMessageSign>
                Video Message by
                <LogoLink
                  onClick={trackViralLink}
                  className="logo-link"
                  href={viralLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoFooter />
                </LogoLink>
              </VideoMessageSign>
          </CustomLayout.Footer>
        </RenderIf>
      </Layout>
    )
  }

  return (
    <LegacyAndV2 props={{ campaign, env }} isV2={isV2Theme}>
      <Headers
        title={pageTitle}
        campaign={campaign}
        sendsparkBrandingEnabled={campaign.sendsparkBrandingEnabled}
        isLinkedInRequest={isLinkedInRequest}
      />
      <Backdrop
        style={{
          zIndex: 10,
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        open={isViralModalOpen}
      />
      <MainContent isEditPage={isEditPage}>
        <EndVideoModal
          isOpen={isViralModalOpen}
          campaign={campaign}
          onClose={() => setShowViralLoop(false)}
          handleOnReplay={handleOnReplay}
        />
        <TitleSection>
          {!isV2Theme && (
            <Logo src={campaign.meta.logo?.url} size={style.logoSize} />
          )}
          <RenderIf condition={!!title}>
            <Title color={style.heading1}>{title}</Title>
          </RenderIf>
        </TitleSection>
        <VideoSection>
          <RenderIf
            condition={
              campaign?.meta?.video?.muxStatus === MUX_STATUS.READY ||
              (campaign?.meta?.video?.uploadingStatus === 'pending' &&
                campaign?.meta?.video?.muxStatus === MUX_STATUS.PREPARING)
            }
          >
            <Player
              isEditPage={isEditPage}
              campaign={(campaign as unknown) as SharedCampaign}
              apiV2Url={env.apiV2Url}
              muxDataEnv={env.muxDataEnv}
              muxDataDebug={env.muxDataDebug}
              analyticsTracker={
                isOwnerVideo ? (null as any) : ahoyAnalyticsTracker
              }
              events={isOwnerVideo ? (null as any) : playerEvents}
              apiV1Url={environmentsConfig.apiV1Url}
              nodeEnv={environmentsConfig.nodeEnv}
              autoPlay={campaign?.featureFlips?.autoPlay && campaign?.autoPlay}
              thumbsUp={campaign?.featureFlips?.thumbsUp && campaign?.thumbsUp}
              gapCombinedVideoPlayer={
                campaign?.featureFlips?.gapCombinedVideoPlayer &&
                campaign?.gapCombinedVideoPlayer
              }
              replayVideo={replayVideo}
              setReplayVideo={setReplayVideo}
            />
          </RenderIf>
          <RenderIf
            condition={
              ['uploading', 'completed'].includes(
                campaign?.meta?.video?.uploadingStatus || ''
              ) && campaign?.meta?.video?.muxStatus === MUX_STATUS.PREPARING
            }
          >
            <VideoPreview>
              <span>
                <div>
                  <SpinnerIcon spinnerBackgroundColor="#00000000" />
                </div>
                <span>Processing...</span>
              </span>
              <img src={env?.videoProcessingGIF} alt="" />
            </VideoPreview>
          </RenderIf>
        </VideoSection>
        <RenderIf condition={!!description}>
          <DescriptionSection>
            <Description color={style.heading2}>{description}</Description>
          </DescriptionSection>
        </RenderIf>
        <RenderIf condition={showCta}>
          <ActionSection>
            <CallToAction
              styles={style}
              message={callToAction}
              trackCTAClick={trackCTAClick}
              isMultipleCTAsSupported={isMultipleCTAsSupported}
            />
          </ActionSection>
        </RenderIf>
      </MainContent>
      <RenderIf condition={!!campaign?.sendsparkBrandingEnabled}>
        <Footer>
          <VideoMessageSign>
            Video Message by
            <LogoLink
              onClick={trackViralLink}
              className="logo-link"
              href={viralLink}
              target="_blank"
              rel="noreferrer"
            >
              <LogoFooter />
            </LogoLink>
          </VideoMessageSign>
        </Footer>
      </RenderIf>
    </LegacyAndV2>
  )
}

export default SharePage
