import styled from 'styled-components';

interface Props {
  color?: string;
}

const Description = styled.p<Props>`
  color: ${({ color }) => color || '#212121'};
  font-family: 'Inter UI', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  word-break: break-word;
  white-space: pre-wrap;
`;

export default Description;
