import styled from 'styled-components';

const HeaderSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
  }
`;

export default HeaderSection;
