import { FC } from 'react';
import RenderIf from '@components/RenderIf';
import DefaultLogo from './styles/DefaultLogo';
import CustomLogo from './styles/CustomLogo';

interface LogoProps {
  src?: string;
  size?: number;
}

const Logo: FC<LogoProps> = ({ src, size = 24 }) => (
  <>
    <RenderIf condition={!!src}>
      <CustomLogo src={src} size={size} />
    </RenderIf>
    <RenderIf condition={!src}>
      <DefaultLogo />
    </RenderIf>
  </>
);

export default Logo;
