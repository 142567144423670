import styled, { CSSObject, css } from 'styled-components';

interface Props {
  layoutMobileOverides?: CSSObject;
}

const LayoutContent = styled.main<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 128px;
  overflow: visible;
  width: 656px;

  @media screen and (max-width: 768px) {
    ${({ layoutMobileOverides }) =>
      layoutMobileOverides && css(layoutMobileOverides)}
  }
`;

LayoutContent.defaultProps = {
  layoutMobileOverides: {
    marginTop: '32px',
    width: '85%',
  },
};

export default LayoutContent;
