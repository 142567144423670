import {
  CampaignMessageKind,
  CampaignMessageSide,
  CampaignMessageView,
} from '@interfaces';
import styled, { css } from 'styled-components';

interface BubbleProps {
  side?: CampaignMessageSide;
  kind?: CampaignMessageKind;
  view?: CampaignMessageView;
  backgroundColor?: string;
  CTAStyle?: string;
  first?: boolean;
  last?: boolean;
  borderRadius?: string;
}

const Bubble = styled.div<BubbleProps>`
  display: inline-block;
  padding: 9px;
  background-color: ${({ CTAStyle, backgroundColor }) =>
    CTAStyle === 'solid' || !CTAStyle ? backgroundColor : 'transparent'};

  border: 2px solid ${({ backgroundColor }) => backgroundColor};

  overflow: hidden;
  box-sizing: border-box;

  transition: filter 0.6s ease-in-out;

  & :hover {
    filter: brightness(0.88);
  }

  ${({ side, first, last = true }) =>
    side === 'left' &&
    css`
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      border-top-left-radius: ${first ? '24px' : '0px'};
      border-bottom-left-radius: ${last ? '24px' : '0px'};
      transform-origin: ${first ? 'bottom left' : 'top left'};
    `}

  ${({ side }) =>
    side === 'right' &&
    css`
      align-self: flex-end;
      margin-left: 0px;
      border-radius: 24px;
      border-bottom-right-radius: 0px;
      transform-origin: bottom right;
    `}

  ${({ kind, view, borderRadius }) =>
    (kind === 'button' || kind === 'link') &&
    css`
      width: 100%;
      border-radius: ${view === 'square' ? borderRadius : '28px'};
      transform-origin: center;
      cursor: pointer;
    `}

  ${({ kind }) =>
    kind === 'asset' &&
    css`
      padding: 0px;
      background: none;
    `}
`;

Bubble.defaultProps = {
  borderRadius: '0',
};

export default Bubble;
