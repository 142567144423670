import { CampaignMessage, CampaignStyle } from '@interfaces';
import { FC } from 'react';
import RenderIf from '@components/RenderIf';
import { CSSObject } from 'styled-components';
import callToActionClick from './callToActionClick';
import CallToActionContainer from './styles/CallToActionContainer';
import Bubble from './styles/Bubble';
import Image from './styles/Image';
import Text from './styles/Text';
import { getMessageColors } from './util';

interface MessageProps {
  additionalStyles?: CSSObject;
  borderRadius?: string;
  styles: CampaignStyle;
  message?: CampaignMessage;
  trackCTAClick?: () => Promise<void>;
}

const Message: FC<MessageProps> = ({
  additionalStyles,
  borderRadius,
  message,
  styles,
  trackCTAClick,
}) => {
  const side = message?.side || 'left';
  const kind = message?.kind;
  const view = message?.view;
  const text = message?.text || 'Button Text';
  const asset = message?.asset;
  const isAsset = kind === 'asset';
  const isButton = kind === 'button' || kind === 'link';
  const messagesColors = getMessageColors(styles, message);
  const textColor = messagesColors?.text;
  const backgroundColor = messagesColors?.background;

  const { CTAStyle } = styles;

  const handleBubbleClick = () => {
    if (isButton) {
      callToActionClick(message, trackCTAClick);
    }
  };

  return (
    <CallToActionContainer side={side} kind={kind}>
      <Bubble
        borderRadius={borderRadius}
        kind={kind}
        side={side}
        view={view}
        backgroundColor={backgroundColor}
        CTAStyle={CTAStyle}
        onClick={handleBubbleClick}
      >
        <RenderIf condition={isAsset}>
          <Image src={asset?.url} alt="asset" />
        </RenderIf>
        <RenderIf condition={!isAsset}>
          <Text
            kind={kind}
            color={textColor || '#FFFFFF'}
            additionalStyles={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              ...additionalStyles,
            }}
          >
            {text || '•••'}
          </Text>
        </RenderIf>
      </Bubble>
    </CallToActionContainer>
  );
};

export default Message;
