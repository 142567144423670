import styled from 'styled-components';

const EndVideoModalStyled = styled.div`
  align-items: center;
  background: #F5EEEB;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 328px;
  justify-content: center;
  padding: 48px;
  position: absolute;
  transform: translateY(-200%);
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0s;
  visibility: hidden;
  width: 392px;
  z-index: 11;

  @media screen and (max-width: 500px) {
    padding: 24px;
  }

  &.animated {
    transform: translateY(0);
    visibility: visible;
  }
  &.hidden {
    transform: translateY(-200%);
    visibility: hidden;
  }
`;

export default EndVideoModalStyled;
