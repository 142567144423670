import styled, { CSSObject, css } from 'styled-components';

interface Props {
  additionalStyles?: CSSObject;
  mobileStyles?: CSSObject;
}

const ActionSection = styled.section<Props>`
  @media screen and (max-width: 768px) {
    ${({ mobileStyles }) => mobileStyles && css(mobileStyles)}
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

ActionSection.defaultProps = {
  /* TODO: remove roche default moving to db */
  additionalStyles: {
    padding: '8px 24px 8px',
    background: 'none',
  },
  mobileStyles: undefined,
};

export default ActionSection;
