import React from 'react';

const SvgFrontFlaps: React.FC = () => (
  <svg
    width="1120"
    height="720"
    viewBox="0 0 1120 720"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_173)">
      <path d="M660 360L1120 0V720L660 360Z" fill="#CC0000" />
      <path d="M660 360L1120 0V720L660 360Z" fill="black" fillOpacity="0.05" />
      <path d="M460 360L0 0V720L460 360Z" fill="#CC0000" />
      <path d="M460 360L0 0V720L460 360Z" fill="black" fillOpacity="0.05" />
      <path d="M0 720H1120L660.5 360H560H460L0 720Z" fill="#CC0000" />
    </g>
    <defs>
      <clipPath id="clip0_1_173">
        <rect width="1120" height="720" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgFrontFlaps;
