import styled, { CSSObject, css } from 'styled-components';

interface Props {
  // TODO: remove remove roche props when moving to db
  padding?: string;
  background?: string;
  additionalStyles?: CSSObject;
  isOracle?: boolean;
  removeBottomRadius?: boolean;
  videoBottomPadding: boolean;
}

const VideoSection = styled.section<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
  width: 100%;
  background: ${({ background }) => background};

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}

  /*
    right now this works only for oracle theme
    we have to find a way to normalice this theme config
   */
  ${({ videoBottomPadding }) =>
    !videoBottomPadding
      ? 'padding-bottom: 0px;'
      : `
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  > div > video {
    border-radius: ${({ isOracle }) => (isOracle ? '6px' : 'none')};
    border-bottom-left-radius: ${({ isOracle, removeBottomRadius }) =>
      isOracle && !removeBottomRadius ? '6px' : '0px'};
    border-bottom-right-radius: ${({ isOracle, removeBottomRadius }) =>
      isOracle && !removeBottomRadius ? '6px' : '0px'};
  }
`;

VideoSection.defaultProps = {
  padding: '24px 24px 16px',
  background: 'transparent',
  isOracle: false,
  removeBottomRadius: true,
};

export default VideoSection;
