import React from "react";

const SvgTopFlapUp: React.FC = () => (
  <svg
    width="1120"
    height="424"
    viewBox="0 0 1120 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_62)">
      <path
        d="M1120 424C1120 371.469 1093.03 333.133 780 130.703C560 -11.5677 560 -11.5677 340 130.703C26.973 333.133 0 371.469 0 424H524H1120Z"
        fill="#CC0000"
      />
      <path
        d="M1120 424C1120 371.469 1093.03 333.133 780 130.703C560 -11.5677 560 -11.5677 340 130.703C26.973 333.133 0 371.469 0 424H524H1120Z"
        fill="black"
        fillOpacity="0.15"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_62">
        <rect width="1120" height="424" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTopFlapUp;