import styled, { css, keyframes } from 'styled-components';

interface EnvelopeBackProps {
  name?: string;
}

// Animations
const fadeOut = keyframes`
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(1300px);
  }
`;

const scaleCard = keyframes`
  0% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const scaleEnvelope = keyframes`
  100% {
    width: 100%;
    height: 100%;
  }
`;

const rotateEnvelope = keyframes`
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const openFlapOuter = keyframes`
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
`;

const openFlapInner = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
`;

const fadeOutAnimation = css`
  ${fadeOut} 1s linear 4s forwards
`;

export const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export const EnvelopeContainer = styled.div`
  perspective: 1000px;
  width: 100%;
  height: 55rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    height: 42rem;
  }
  @media (max-width: 745px) {
     height: calc(100vw * 0.5);
  }
`;

export const Envelope = styled.div`
  position: absolute;
  width: 70%;
  height: 70%;
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
animation: ${rotateEnvelope} 2s cubic-bezier(0.42, -0.4, 0.58, 1.4) 1s
      forwards,
    ${scaleEnvelope} 0.8s linear 4s forwards;
  z-index: 2;

  .svg-parent svg {
    height: auto;
    width: 100%;
  }
`;

export const EnvelopeFront = styled.div`
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotateY(0deg);
`;

export const EnvelopeBack = styled.div<EnvelopeBackProps>`
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);

  &:before {
    content: '${(props) => props?.name}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
`;

export const TopFlapOpen = styled.div`
  position: absolute;
  top: -58%;
  backface-visibility: hidden;
  transform-origin: bottom;
  z-index: 0;
  transform: rotateX(180deg);
  animation: ${openFlapOuter} 1s linear 3s forwards, ${fadeOutAnimation};
`;

export const TopFlapClose = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transform-origin: top;
  z-index: 3;
  animation: ${openFlapInner} 1s linear 3s forwards, ${fadeOutAnimation};
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: ${scaleCard} 1s linear 4s both;
`;

export const FlapsBody = styled.div`
  position: absolute;
  z-index: 2;
  animation: ${fadeOutAnimation};
`;

export const BackFace = styled.div``;

export const Throat = styled.div`
  animation: ${fadeOutAnimation};
`;