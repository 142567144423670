import styled from 'styled-components';

const VideoMessageSign = styled.p`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: Larsseit, san-serif;
  font-size: 13px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
`;

export default VideoMessageSign;
