import styled from 'styled-components';

interface Props {
  background?: string;
  hideBorders?: boolean;
}

const MainContent = styled.main<Props>`
  align-items: center;
  background: ${({ background }) => background || '#ffffff'};
  border-bottom: ${({ hideBorders }) =>
    hideBorders ? 'none' : '1px solid #DDDDDD'};
  border-left: ${({ hideBorders }) =>
    hideBorders ? 'none' : '1px solid #DDDDDD'};
  border-right: ${({ hideBorders }) =>
    hideBorders ? 'none' : '1px solid #DDDDDD'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: visible;
  width: 100%;
`;

export default MainContent;
