import * as React from 'react';

import CloseButtonStyled from './CloseButtonStyled';

interface IEndVideoModalProps {
  handleOnClick(): void;
}

const CloseButton: React.FunctionComponent<IEndVideoModalProps> = ({ handleOnClick }) => {
  return (
    <CloseButtonStyled onClick={handleOnClick}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11M11 1L1 11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </CloseButtonStyled>
  )
};

export default CloseButton;
