import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useIsEditPage } from '@hooks'
import { useCrypto } from '@hooks/lib/useCrypto'
import { useLocalStorageV2 } from '@hooks/lib/useLocalStorageV2'
import { LOCAL_STORAGE_KEYS } from '@constants/constants'
import { removeQueryParamsFromRouter } from '@utils'
import { RenderIfOwnerProps } from './renderIfOwner'
import RenderIf from '@components/RenderIf'

const RenderIfOwner = ({ cryptoKey, isOwnerVideo, customCondition = true, children }: RenderIfOwnerProps) => {
  const router = useRouter()
  const isEditPage = useIsEditPage()
  const { decryptString } = useCrypto(cryptoKey)
  const { setLocalStorage } = useLocalStorageV2(cryptoKey)
  const [renderChildren, setRenderChildren] = useState<boolean>(false)

  useEffect(() => {
    const { preview } = router.query
    if (preview) {
      const previewDecrypted = decryptString(decodeURIComponent(preview as string))
      setLocalStorage({
        key: LOCAL_STORAGE_KEYS.USER_SESSION_ID,
        value: previewDecrypted,
        crypto: true,
        expire: true
      })

      removeQueryParamsFromRouter(router, ['preview'])
      return
    }
  }, [])

  useEffect(() => {
    const conditionsArray = [!isEditPage, isOwnerVideo, customCondition]
    const validationToRenderChildren = conditionsArray.every((condition) => condition)
    setRenderChildren(validationToRenderChildren)
  }, [isEditPage, isOwnerVideo, customCondition])

  return (
    <>
      <RenderIf condition={renderChildren}>
        <></>
      </RenderIf>

      <RenderIf condition={renderChildren}>
        {children}
      </RenderIf>
    </>
  )
}

export default RenderIfOwner