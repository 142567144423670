import Layout from '@components/CompoundLayout'
import NavBar from '@components/NavBar'
import { Campaign, EnvConfigShare } from '@interfaces'
import React, { FC, useState } from 'react'
import VideoPreview from './VideoPreview'
import LockIcon from '@assets/LockIcon'
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { generateProtectedCampaignAuthAccess, getCampaign } from '@api-v2/campaigns'
import KeyIconSvg from '@assets/KeyIconSvg'
import {
  CTAPlaceholder,
  CalendarPlaceholder,
  DescriptionPlaceholder,
  PlaceholdersWrapper,
  TitlePlaceholder,
} from '@components/ShareInfoPlaceholders/styles'
import useTopToastNotification from '@hooks/useTopToastNotification'
import RenderIf from '@components/RenderIf'
import Headers from '@components/Headers'
import { useSegmentTracker } from '@hooks'
import { SegmentEvents } from '@constants/SegementEvents'

interface VideoProtectedPageProps {
  campaign: Campaign
  env: EnvConfigShare
  setCampaign: (campaign: Campaign) => void
  setProtectedCampaign: (isProtected: boolean) => void
  isLinkedInRequest: boolean
}

const VideoProtectedPage: FC<VideoProtectedPageProps> = ({
  campaign,
  env,
  setCampaign,
  setProtectedCampaign,
  isLinkedInRequest,
}) => {
  const { styles } = campaign
  const { notification } = useTopToastNotification()
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false)
  const campaignCreatorWorkspaceId = campaign.creator?.workspaceId

  const segmentTracker = useSegmentTracker(
    env.apiV2Url,
    campaignCreatorWorkspaceId,
    env.cryptoKey
  )
  const formik = useFormik({
    initialValues: {
      campaignPassword: '',
    },
    onSubmit: async values => {
      setLoadingCampaign(true)
      const campaignAuthToken = await generateProtectedCampaignAuthAccess(campaign?.shareId || '', values.campaignPassword, {
        baseURL: env.apiV2Url,
      })

      const completeCampaign = await getCampaign(campaign?.shareId || '', undefined, undefined, {
        baseURL: env.apiV2Url,
        headers: {
          Authorization: campaignAuthToken.token,
        },
      })

      if (completeCampaign?.restricted) {
        segmentTracker(SegmentEvents.PASSWORD_PROTECTED_ENTERED, {
          campaignId: campaign?.shareId,
          correct_password: false,
          restrictionReason: completeCampaign?.restrictionReason
        })
        notification('Invalid password', { variant: 'error' })
        setLoadingCampaign(false)
        return
      }
      segmentTracker(SegmentEvents.PASSWORD_PROTECTED_ENTERED, {
        campaignId: campaign?.shareId,
        correct_password: true,
      })
      setCampaign(completeCampaign)
      setProtectedCampaign(false)
      setLoadingCampaign(false)
    },
  })

  const handleCopyLink = async (): Promise<void> => {
    const viewUrl = `${campaign?.shareUrl}${campaign?.shareId}`
    try {
      await navigator.clipboard.writeText(viewUrl)
      // notification('Video link has been copied!')
    } catch (err) {
      console.error('Failed to copy text: ', err)
      // notification('Failed to copy link!')
    }
  }

  return (
    <Layout>
      <Headers
        title={campaign?.meta?.name}
        campaign={campaign}
        isLinkedInRequest={isLinkedInRequest}
      />
      <Layout.Top>
        <NavBar
          background={styles?.backgroundColor}
          border={{ color: styles?.borderColor }}
          icon={{ color: styles?.iconColor }}
          url={campaign.meta.logo?.url}
          handleOnCopyLink={handleCopyLink}
        />
      </Layout.Top>
      <Layout.Main>
        <Layout.Left>
          <VideoPreview backgroundColor="linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%)">
            <div>
              <LockIcon />
              <Typography variant="h1">This video is private</Typography>
              <Typography variant="body1">
                If you have the password, enter it down below…
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id="campaignPassword"
                  value={formik.values.campaignPassword}
                  onChange={formik.handleChange}
                  variant="outlined"
                  type="password"
                  placeholder="Enter password"
                  required
                />
                <Button type="submit">
                  <RenderIf condition={loadingCampaign}>
                    <CircularProgress size={24} />
                  </RenderIf>
                  <RenderIf condition={!loadingCampaign}>
                    <KeyIconSvg />
                  </RenderIf>
                </Button>
              </form>
            </div>
          </VideoPreview>
          <PlaceholdersWrapper>
            <TitlePlaceholder />
            <DescriptionPlaceholder />
            <CTAPlaceholder />
          </PlaceholdersWrapper>
        </Layout.Left>
        <Layout.Right>
          <CalendarPlaceholder />
        </Layout.Right>
      </Layout.Main>
    </Layout>
  )
}

export default VideoProtectedPage
