import * as React from 'react';


import RenderIf from '@components/RenderIf';
import Layout from '@components/CompoundLayout';
import NavBar from '@components/NavBar';
import { StyledWrapper } from '@components/CompoundLayout/styles';
import { Campaign, EnvConfigShare } from '@interfaces';
import Calendar from '@components/Calendars';
import RenderIfOwner from '@components/RenderIfOwner';
import useTopToastNotification from '@hooks/useTopToastNotification';
import { useIsEditPage } from '@hooks';
import Banner from '@components/Banner';
import useIsOwnerVideo from '@hooks/useIsOwnerVideo';
import PageContainer from '@components/Layout/styles/PageContainer';

interface ILegacyAndV2Props {
  props: { campaign: Campaign, env: EnvConfigShare };
  children: React.ReactNode;
  isV2: boolean;
}

const LegacyAndV2 = ({ props: { campaign, env }, isV2, children }: ILegacyAndV2Props) => {
  const { isOwnerVideo } = useIsOwnerVideo({ campaign, env });
  const {
    // meta: { name, video, heading1 },
    shareId,
    shareUrl
  } = campaign
  // TODO: Uncomment when handleEdit and handdleDownload are implemented
  // const mp4Url = video?.sources?.find(({ type }) => type === 'video/mp4')?.src
  // const videoName = heading1 || name || video?.name
  const hasCalendar = !!campaign?.calendar?.provider && !!campaign?.calendar?.link;
  const { notification } = useTopToastNotification()
  const isEditPage = useIsEditPage()

  // TODO: Uncomment when handleEdit and handdleDownload are implemented
  // const handleEdit = (): void => {
  //   window.open(`${env?.appUrl}/edit/${campaign?.id}`, '_self')
  // }

  // TODO: Uncomment when handleEdit and handdleDownload are implemented
  // const handdleDownload = (): void => {
  //   window.open(`${mp4Url}?download=${videoName}`, '_self')
  //   notification('Downloading video!')
  // }

  const handleCopyLink = async (): Promise<void> => {
    const viewUrl = `${shareUrl}${shareId}`
    try {
      await navigator.clipboard.writeText(viewUrl);
      notification('Video link has been copied!')
    } catch (err) {
      console.error('Failed to copy text: ', err);
      notification('Failed to copy link!')
    }
  }

  if (isV2) {
    return (
      <Layout>
        <RenderIf condition={!isEditPage}>
          <Layout.Top>
            <NavBar
              url={campaign.meta.logo?.url}
              // isOwnerVideo={isOwnerVideo}
              // handleOnEdit={handleEdit}
              // handleOnDownload={handdleDownload}
              handleOnCopyLink={handleCopyLink}
            />
          </Layout.Top>
          <RenderIfOwner cryptoKey={env.cryptoKey || ''} isOwnerVideo={isOwnerVideo}>
            <Banner
              text="This is a preview. Your view will not show in analytics."
              textButton="Edit Video"
              link={`${env.editPageUrl}${campaign?.id}`}
            />
          </RenderIfOwner>
        </RenderIf>
        <Layout.Main>
          <Layout.Left>
            <StyledWrapper>
              {children}

            </StyledWrapper>
          </Layout.Left>
          <RenderIf condition={hasCalendar}>
            <Layout.Right>
              <Calendar calendar={campaign?.calendar} />
            </Layout.Right>
          </RenderIf>
        </Layout.Main>
      </Layout>
    );
  }

  return (
    <>
      <RenderIfOwner cryptoKey={env.cryptoKey || ''} isOwnerVideo={isOwnerVideo}>
        <Banner
          text="This is a preview. Your view will not show in analytics."
          textButton="Edit Video"
          link={`${env.editPageUrl}${campaign?.id}`}
        />
      </RenderIfOwner>
      <PageContainer background={campaign?.style?.background} isEditPage={isEditPage}>
        <Layout>
          <Layout.Main>
            <Layout.Left>
              <StyledWrapper>
                {children}
              </StyledWrapper>
            </Layout.Left>
            <RenderIf condition={hasCalendar}>
              <Layout.Right>
                <Calendar calendar={campaign?.calendar} />
              </Layout.Right>
            </RenderIf>
          </Layout.Main>
        </Layout>
      </PageContainer>
    </>
  )
};

export default LegacyAndV2;
