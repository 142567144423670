import styled from 'styled-components';

interface Props {
  color?: string;
}

const Title = styled.h1<Props>`
  font-family: 'Inter UI', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ color }) => color || '#000000'};
  text-align: center;
  margin-bottom: 32px;
  word-break: break-word;
`;

export default Title;
