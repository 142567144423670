import styled from 'styled-components';
import SpinnerIconSvg from '../assets/SpinnerIconSvg';

interface Props {
  spinnerBackgroundColor?: string;
}

const SpinnerIcon = styled(SpinnerIconSvg)<Props>`
  fill: ${({ spinnerBackgroundColor }) =>
    spinnerBackgroundColor?.toLowerCase() !== '#ffffff'
      ? spinnerBackgroundColor
      : '#54aa6f'};
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  animation: 1.25s linear infinite spinner;
  transition: transform 0.25s ease, width 0.5s ease, height 0.5s ease,
    left 0.5s ease, top 0.5s ease;

  @media screen and (max-width: 500px) {
    & {
      width: 72px;
      height: 72px;
      left: calc(50% - 36px);
      top: calc(50% - 36px);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default SpinnerIcon;
