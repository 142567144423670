import styled from 'styled-components';

interface Props {
  background?: string;
  isEditPage?: boolean;
}

const PageContainer = styled.div<Props>`
  background: ${({ background }) => background || '#ffffff'};
  ${({ isEditPage }) =>
    isEditPage
      ? `
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    height: 100%;
    overflow: scroll;
    position: absolute;
    width: 100%;
  `
      : ''};
`;

export default PageContainer;
