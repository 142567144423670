import styled from 'styled-components'

interface Props {
  embed?: boolean
}

export const BannerStyles = styled.div<Props>`
  background: #6140FF;
  color: #fff;
  padding: 12px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  line-height: 24px;

  a {
    color: #fff;
  }
  /* Breakpoints 05 */
  @media screen and (max-width: 430px) {
    line-height: 20px;
  }

`
