/* eslint-disable no-undef */
import { useCallback } from 'react';
import { useSnackbar, OptionsObject } from 'notistack';

const useTopToastNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notification = useCallback(
    (message: string, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        ...options,
      }),
    []
  );

  return { notification };
};

export default useTopToastNotification;
